import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { formatMonto, formatMontoR } from '../utils';
import ButtonMinus from './buttonMinus';
import ButtonPlus from './buttonPlus';
import ButtonTrash from './ButtonTrash';
import { useTranslation } from 'react-i18next';
const DetailCarPay = ({
    setShowMoneda,
    moneda,
    symbol,
    converting,
    setMoneda,
    Productos,
    totalConvert,
    Total,
    SubTotal,
    Descuento
}) => {
    const { t } = useTranslation();

    const carStore = useSelector((store) => store.car);
    const shop = useSelector((store) => store.shop);
    //

    useEffect(() => {
        if (carStore.productos.length === 0) {
            document.location.href = '/';
        }
    }, [carStore.productos]);
    const changeCurrency = () => {
        const newMoneda = moneda === 'USD' ? 'VES' : 'USD';
        setMoneda(newMoneda);
    };

    return (
        <span>
            <div className="top">
                <h2 className="textSubTitle">{t('Resumen')}</h2>
            </div>
            <div className="carrito">
                {/* {Productos === null ? ( */}
                {Productos === null ? (
                    <div className="d-flex align-self-center mx-auto justify-content-center p-5">
                        <div
                            className="spinner-border text-secondary spinner-custom"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className="detalle">
                        <div className="productos">
                            {Productos.length > 0 &&
                                carStore.productos.map((p) => {
                                    let prodTemp = Productos.find(
                                        (n) => n.id === p.id
                                    );
                                    let {
                                        TotalVariante: totalCV,
                                        prop: propDetail
                                    } = getTotalConVariantes(prodTemp, p);
                                    return (
                                        <div
                                            className="itemCar d-flex"
                                            key={p.id}
                                        >
                                            <div className="d-flex">
                                                <figure>
                                                    <img
                                                        src={
                                                            prodTemp &&
                                                            prodTemp
                                                                .picture_urls[0]
                                                        }
                                                        alt="img prod"
                                                    />
                                                </figure>
                                                <div>
                                                    <p className="nombre">
                                                        {prodTemp &&
                                                            prodTemp.name}
                                                    </p>
                                                    <span className="descripcion">
                                                        {prodTemp &&
                                                            prodTemp.details}
                                                    </span>
                                                    <p
                                                        className="descripcion"
                                                        style={{
                                                            color: '#000'
                                                        }}
                                                    >
                                                        {propDetail}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="controls d-flex">
                                                    <ButtonMinus
                                                        car={carStore.productos}
                                                        id={p.id}
                                                    />
                                                    <span>{p.cantidad}</span>
                                                    <ButtonPlus
                                                        car={carStore.productos}
                                                        id={p.id}
                                                    />
                                                </div>
                                                <span className="mx-2">
                                                    <ButtonTrash
                                                        car={carStore.productos}
                                                        id={p.id}
                                                    />
                                                </span>
                                                <span className="d-flex align-items-end flex-column">
                                                    <div className="precio">
                                                        {
                                                            prodTemp.custom_data
                                                                .conversion
                                                                .currency.symbol
                                                        }
                                                        {(
                                                            totalCV * p.cantidad
                                                        ).toFixed(2)}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="montos">
                            <div className="subTotal d-flex justify-content-between mb-2">
                                <span>SubTotal</span>
                                <span>
                                    {Productos[0] &&
                                        Productos[0].custom_data.conversion
                                            .currency.symbol}
                                    {SubTotal.toFixed(2)}
                                </span>
                            </div>
                            {/* {moneda !== 'USD' && (
                                <div className="subTotal d-flex justify-content-between mb-2">
                                    <span>
                                        {t('Total En')} {moneda}
                                    </span>
                                    <span>
                                        {Productos[0] &&
                                            Productos[0].custom_data.conversion
                                                .currency.symbol}
                                        {SubTotal.toFixed(2)}
                                    </span>
                                </div>
                            )} */}
                            {Descuento > 0 && (
                                <div className="subTotal d-flex justify-content-between">
                                    <span>Descuento</span>
                                    <span>${Descuento.toFixed(2)}</span>
                                </div>
                            )}
                            {/* {moneda !== "USD" && (
                <div className="envio d-flex justify-content-between">
                    <span>Cambio moneda</span>
                    <span>$ {formatMonto(cambio[moneda], 2, ",", ".")}</span>
                </div>
                )} */}
                        </div>
                        <h2 className="total d-flex justify-content-between">
                            <span>
                                Total {moneda !== shop.config.ShopCurrency && `en ${shop.config.ShopCurrencyConvert}`}
                            </span>
                            <span>
                                {moneda === shop.config.ShopCurrency
                                    ? '$ ' + Total
                                    : totalConvert &&
                                      formatMontoR(totalConvert, 2, ',', '.')}
                            </span>
                        </h2>
                        <div className="cambiarMoneda d-flex w-100 justify-content-end">
                            <span onClick={changeCurrency}>
                                {moneda === "USD"
                                    ? `Pagar en ${shop.config.ShopCurrencyConvertName}`
                                    : `Pagar en ${shop.config.ShopCurrencyName}`}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </span>
    );
};

export default DetailCarPay;

const getTotalConVariantes = (productoOrigin, pCar) => {
    let _tV = parseFloat(
        formatMonto(
            productoOrigin.custom_data.conversion.display_price_e2,
            2,
            '',
            '.'
        )
    );
    let _propDet = '';
    Object.keys(pCar.variantes).map((v) => {
        let prop = productoOrigin.branch_good_properties.find(
            (n) => n.property_id === parseInt(v.replace('op_', ''))
        );
        let op = productoOrigin.branch_good_properties
            .find((n) => n.property_id === parseInt(v.replace('op_', '')))
            ?.property_options.find((n) => n.id === pCar.variantes[v]);
        if (op) {
            _tV += parseFloat(formatMonto(op.price_e2, 2, ',', '.'));
        }
        _propDet = prop && op ? `${prop.display_name}: ${op.name}  (${formatMonto(op.price_e2, 2, ',', '.')})` : '';
    });
    return {
        TotalVariante: _tV,
        prop: _propDet
    };
};
